angular.module('MyHippoCommons.Directives').directive('ratingStarsModal', function () {
    return {
        restrict: 'E',
        templateUrl: '/modals/rating-stars/rating-stars.html',
        controller: function ($scope, $mdDialog) {
            $scope.stars = new Array(5);
            $scope.cancel = function () {
                $mdDialog.hide();
            };
        },
    };
});
